.card-container {
    width: 100%;
    max-width: 500px;
    height: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    min-width: 300px;
    opacity: 1;
    animation: appear ease-in 1s;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.card-container .user-profile-picture {
    height: 100%;
    max-height: 200px;
    width: 100%;
    max-width: 200px
}

.card-container .user-name {
    color: white;
    font-size: 24px;
    margin: 10px;
    text-align: center;
}