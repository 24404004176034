.header-container {
    height: 80px;
    position: relative;
}

.header-container .seb-logo-link {
    margin-right: 110px;
    margin-top: -20px;
}

.header-container .time-badge {
    display: flex;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
    background: linear-gradient(347.16deg, rgb(15, 106, 207) 8.18%,#009ADC  114.87%);
    font-family: 'Poppins', sans-serif;
    position: absolute;
    right: 10px;
    bottom: -9px;
    height: 34px;
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
    padding: 0 32px;
    z-index: 100;
    border-radius: 3px;

    -webkit-transform: skew(-10deg); /* Chrome, Opera */
    -ms-transform: skew(-10deg); /* IE */
    transform: skew(-10deg); /* Padrão */
}

@media screen and (max-width: 1160px) {
    .header-container h1 {
        position: absolute;
        left: 50%;
        top: 100px;
        z-index: 10;
        width: 100%;
        transform: translateX(-50%);
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .header-container h1 {
        font-size: 16px;
    }

    .header-container .seb-logo-link {
        margin-right: 80px;
    }

    .header-container .mem-logo-link svg {
        width: 200px;
    }

    .header-container  .time-badge {
        width: 200px;
        font-size: 12px;
    }
}