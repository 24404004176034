.user-log-list-container {
    margin: 10px;
    overflow-y: auto;
    height: auto;
    max-height: calc(100% - 145px);
    width: 100%;
    max-width: 800px;
    min-height: 400px;
}

.user-log-list-container li:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
}

.user-log-list-container::-webkit-scrollbar {
    width: 0;
}

.user-log-list-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.user-log-list-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.user-log-list-container li img {
    width: 60px;
    height: 60px;
}

.user-log-list-container li .user-log-info-group {
    text-align: end;
}

.user-log-list-container li .user-name,
.user-log-list-container li .user-log-info-group {
    font-size: 22px;
}

.user-log-list-container li .user-log-time {
    font-size: 18px;
    text-align: end;
    line-height: 25px;
}
