.main-layout-container {
    height: 100%;
    overflow-x: hidden;
}

.main-layout-container main {
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    margin-top: 9px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: url("../../../../public/images/background.png");
    background-size: cover;
    max-height: calc(100% - 90px);
}


.main-layout-container main:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-image: url("../../../../public/images/background.png");
    background-size: cover;
    filter: blur(2px);

    transform: rotateY(0deg) rotateX(0deg) scale(0.2);
    transform-style: preserve-3d;
    animation: 20s flipme linear infinite;
    transition: transform 300ms ease;
    transform-origin: 50% 50%;
}

@keyframes flipme {
    0% {
        transform: rotateY(-30deg) rotateX(0deg);
    }
    50% {
        transform: rotateY(0deg) rotateX(10deg);
    }
    100% {
        transform: rotateY(-30deg) rotateX(0deg);
    }

}

.main-layout-container header {
    display: block;
    height: 81px;
}

.main-layout-container .user-card-container {
    flex: 1;
}

.main-layout-container .users-list-container {
    height: 100%;
    width: 50%;
    min-width: 560px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-layout-container .signin-greetings {
    text-align: center;
    color: white;
    font-family: Poppins, sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0;
}

.main-layout-container .user-last-signed-container.appear {
    opacity: 1;
    animation: appear ease-in-out 1s;
    /*animation-delay: 1s;*/
}

.main-layout-container .user-last-signed-container {
    z-index: 10;
}

.main-layout-container .user-last-signed-container .card-container {
    padding: 20px;
}

.main-layout-container .user-last-signed-container.disappear {
    opacity: 0;
    animation: disappear ease-in-out 1s;
}

@keyframes appear {
    0% {
        opacity: 1;
        transform: scale(0);
    }
    50% {
        opacity: 0;
        transform: scale(.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes disappear {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(.7);
    }
}


@media screen and (max-width: 1120px) {
    .main-layout-container .user-last-signed-container {
        margin-top: 80px;
    }
}

@media screen and (max-width: 480px) {
    .main-layout-container main {
        padding: 10px;
    }

    .main-layout-container .users-list-container h2 {
        font-size: 20px;
    }

    .main-layout-container .users-list-container {
        min-width: 100%;
    }
}