@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #194270;
    --secondary-color: rgba(0, 154, 220, 0.13);
}

html, body {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    background: linear-gradient(347.16deg, #009ADC 8.18%, rgba(15, 106, 207, 0.79) 114.87%);
}

main {
    overflow-x: hidden;
}

img svg {
    width: 100%;
    height: 100%;
}

table {
    border-collapse: separate;
    border-spacing: 0 11px;
}

td {
    border: solid 1px #ffffff;
    padding: 10px;
    border-left: 0;
    border-right: 0;
}

tbody tr {
    margin-bottom: 10px;
}

td:first-child {
    border-left: 1px;
}

td:last-child {
    border-right: 1px;
}

tr td:first-child {
    border-top-left-radius: 6px;
}
tr td:last-child { border-top-right-radius: 6px; }

tr td:first-child { border-bottom-left-radius: 6px; }
tr td:last-child { border-bottom-right-radius: 6px; }

/*tr:first-child td { border-top-style: solid; }*/
tr td:first-child { border-left-style: solid; }

.date-badge {
    transform: translateY(-10px);
    background: linear-gradient(347.16deg, #009ADC 8.18%, rgba(15, 106, 207, 0.79) 114.87%);
}


main {
    max-height: calc(100vh - 64px);
    height: 100%;
    overflow: hidden;
}

.user-profile-picture {
    color: white;
    background: url("../public/svgs/user-icon.svg");
}

.glass {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.main-layout-container .seb-time-container {
    display: none;
}

@media screen and (min-width: 3000px) {
    #root .main-layout-container main {
        gap: 600px !important;
        background-size: contain !important;
    }

    #root .main-layout-container main:before {
        background-size: contain !important;
    }

    .main-layout-container .user-last-signed-container {
        transform: scale(1.8);
    }

    #root > div > div > main > div.users-list-container > div > h2 {
        font-size: 70px;
    }

    .main-layout-container .users-list-container .user-log-list-container h2 {
        font-size: 50px;
    }

    .main-layout-container .users-list-container .user-log-list-container {
        max-width: 1000px;
    }

    .main-layout-container .header-container h1 {
        font-size: 58px;
        padding-right: 300px;
    }

    .main-layout-container .header-container > div {
        justify-content: space-around;
        display: flex;
        flex-direction: row;
    }

    .main-layout-container .header-container .time-badge {
        display: none;
    }

    .main-layout-container .seb-time-container {
        display: block;
    }

    .main-layout-container .users-list-container,
    .main-layout-container .users-list-container .user-log-list-container {
        max-width: 1200px !important;
        min-width: 1200px !important;
        width: 1200px;
    }

    .main-layout-container .header-container .seb-logo-link {
        margin-top: 0;
    }

    .main-layout-container .seb-time-container .seb-logo-link svg {
        width: 400px;
        height: 400px;
        filter: brightness(100);
    }

    .main-layout-container .seb-time-container .card-container {
        max-width: 100%;
        max-height: 800px;
        height: 600px;
        display: flex;
        flex-direction: column;
        width: 600px;
    }

    .main-layout-container .seb-time-container .time-badge {
        display: flex;
        justify-content: center;
        align-content: center;
        vertical-align: middle;
        /*background: linear-gradient(347.16deg, rgb(15, 106, 207) 8.18%,#009ADC  114.87%);*/
        font-family: 'Poppins', sans-serif;
        height: 130px;
        width: 400px;
        font-style: italic;
        font-weight: 700;
        font-size: 40px;
        text-align: center;
        letter-spacing: 0.07em;
        color: #FFFFFF;
        padding: 0 32px;
        z-index: 100;
        border-radius: 3px;

        transform: scale(1.5); /* Padrão */
    }

    .main-layout-container .users-list-container .user-log-list-container li p.user-name {
        font-size: 40px;
        line-height: 50px;
    }

    .main-layout-container .users-list-container .user-log-list-container li .user-log-info p.user-log-info-group {
        font-size: 30px;
        line-height: 50px;
    }

    .main-layout-container .users-list-container .user-log-list-container li .user-log-info p.user-log-time {
        font-size: 20px;
        line-height: 50px;
    }

    .main-layout-container .users-list-container .user-log-list-container .user-profile-picture {
        height: 100px;
        width: 100px;
    }
}

@media screen and (max-width: 1128px) {
    .main-layout-container .header-container {
        display: none !important;
    }

    /*.main-layout-container .header-container .mem-logo-link svg {*/
    /*    width: 200px;*/
    /*}*/

    .main-layout-container .user-last-signed-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-top: 0 !important;
    }

    .main-layout-container main {
        height: 100% !important;
        max-height: 100% !important;
        margin-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0;
        /*flex-direction: column;*/
    }

    .main-layout-container .users-list-container {
        max-height: 50px !important;
        overflow: hidden;
    }

    .main-layout-container .users-list-container .user-log-list-container {
        display: none !important;
    }

    .main-layout-container .user-last-signed-container .card-container {
        flex-direction: column !important;
        min-height: 400px !important;
    }
}

@media screen and (max-width: 1128px) and (min-height: 1000px) {
    .main-layout-container main {
        flex-direction: column;
    }
}